import "./VideosListLoading.css";

export function VideosListLoading({ show }) {
  if (!show) return null;
  return (
    <div className="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
